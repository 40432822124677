body{
	background-image: url("../img/bg-pat.png");
}

.mv{
	width: 100%;
	height: 654px;
	background-image: url("../img/mv2.png");
	background-position: center top;
	background-repeat: no-repeat;
	text-align: center;
	padding-top: 226px;
	@media screen and (max-width: 600px) {
		img{width: 100%;}
	}
}

.footer{
	height: 146px;
	padding-top: 2em;
	text-align: center;
	color: #FFF;
	//background-image: url("../img/footer_bg.png");
	background-color: #251605;
	margin-top: 3em;
}

.container{
	margin-top: 30px;
}

.news_ttl{
	font-size: 3.0rem;
	border-bottom: 2px solid #666;
}


.blog_header{
	display: flex;
	justify-content: space-between;
}

.blog_area{
	img{width: 100%;height: auto;}
}

.blog_ttl{
	font-size: 2.2rem;
}

.blog_date{
	margin-top: 0em;
}

.blog_cat > a{
	display: block;
	background-color: #d37ae6;
	text-align: center;
	color: #fff;
	font-family: 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

	&:hover{
		opacity: 0.8;
	}
}

.blog_cat.social > a{
	display: block;
	background-color: #eb5c60;
	text-align: center;
	color: #fff;
	font-family: 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

	&:hover{
		opacity: 0.8;
	}
}

.blog_cat_s {
	display: inline-block;
	margin-left: 0.5em;
	padding: 0 0.5em;
	background-color: #d37ae6;
	text-align: center;
	color: #fff;
	font-family: 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

	&:hover{
		opacity: 0.8;
	}
	&.social{
		background-color: #eb5c60;
	}
}

.blog_area{
	> p{
		margin-bottom: 2em;
		font-size: 1.8rem;
		line-height: 1.8;
	}
	h2{
		border-bottom: 4px double #ccc;
		font-size: 2.0rem;
		margin-bottom: 1em;
	}
	img{
		margin-bottom: 1em;
	}
	a.btn{
		display: block;
    text-align: center;
    background-color: #d37ae6;
    color: #fff;
    padding: 1em;
    &:hover{
    	opacity: 0.8;
    	transition: all 0.2s;
    }
	}
}

.blog_sec{
	> p{
		margin-bottom: 2em;
		font-size: 1.8rem;
		line-height: 1.8;
	}
	h2{
		margin-bottom: 1em;
		border-bottom: 1px solid #ccc;
		font-size: 2.2rem;
	}

}

.blog_em{
	font-weight: bold;
}

.next_prev{
	.prev_link{float: left;}
	.next_link{float: right;}
	margin-bottom: 3em;
}


.cnt_ttl{
	border-bottom: 1px solid #ccc;
	margin-bottom: 1em;
	font-size: 2.4rem;
	&::before{
		content:"";
		margin-right: 10px;
		display: inline-block;
		position: relative;
		top: 3px;
		width: 27px;
		height: 27px;
		background-image: url("../img/icon_earth.png");
		background-repeat: no-repeat;
	}
}


.recent_posts{
	>li{height: 100px;}
	>li a{
		display: flex;
		justify-content: space-between;
		font-size: 1.2rem;
		margin-bottom: 1em;
		color: #333;
		.recent_txt{
			margin-left: 1em;
			width: 100%;
			.r_date{
				text-align: right;
				margin-top: 0.5em;
			}
		}

		&:hover{
			text-decoration: none;
			opacity: 0.8;
			transition: all 0.2s;
		}

	}
}

.recent_link{
	a{
		display: block;
		color: #FFF;
		padding: 1em;
		background-color: #d37ae6;
		border-bottom: 2px solid #ccc;
		text-align: center;
		&:hover{
			text-decoration: none;
			opacity: 0.8;
			transition: all 0.2s;
		}
	}
}


//sidebar

.side_bnr_list{
	>li{
		margin-bottom: 1em;
		a{
			&:hover img{
				opacity: 0.8;
				transition: all 0.2s;
			}
		}
	}
}

.side_ttl{
	border-bottom: 1px solid #ccc;
	margin-top: 2em;
	margin-bottom: 1em;
	font-size: 1.8rem;
	&::before{
		content:"";
		margin-right: 10px;
		display: inline-block;
		position: relative;
		top: 3px;
		width: 27px;
		height: 27px;
		background-image: url("../img/icon_earth.png");
		background-repeat: no-repeat;
	}
}

.note{
	font-size: 1.4rem;
	background-color: #F9F2D6;
	margin-bottom: 1.5em;
}

.fwb{
	font-weight: bold;
}

.mb1em{
	margin-bottom: 1em;
}

.ascention_date{
	>li{
		margin-bottom: 1em;
		a{
			color: #333;
			text-decoration: underline;
			&:hover{
				opacity: .8;
			}
		}
	}
}

blockquote{
    margin-bottom: 3em;
    border: 1px solid #ccc;
    padding: 1em;
}

.cat-item{
	list-style-type: none;
	.children{
		text-indent: 1em;
	}
}

iframe.argo {
    display: none;
}